.sampleCodegiv1 {
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  border: 1px solid black;
  border-radius: 5px;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 500px;
  background-color: #F5F2F0;
  width: 32%;
  margin-left: 10px;
  /* Hide scrollbar for Chrome, Safari, and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.mainsecvs{
  display: flex;
  border-radius: 10px;

  justify-content: space-around;
  align-items: flex-start;
}
.mainsecvsmain1{
  display: flex;
  justify-content: center;

  padding: 50px;
  font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.mainsecvsmain {
  background-color: #8efcbf;
  background-image: linear-gradient(62deg, #8efcbf 0%, #E0C3FC 50%);
  

 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 padding-bottom: 50px;

}
.jusblurgif{

  width: 100%;
}


.samplechartgif1{
  width: 1000px;
  border: 1px solid black;
  border-radius: 5px;
}

.givonebar{
  display: none;
}
.mainsecvsmain1 h1 {
  display: flex;
  justify-content: center;
  position:relative; font-size:20px; font-weight:700; align-items: center; letter-spacing:0px; text-transform:uppercase; width:300px;  margin:auto; white-space:nowrap; border:2px solid #222;padding:5px 11px 3px 11px;
}
.mainsecvsmain1 h1:before, .mainsecvsmain1 h1:after {
    background-color: #c50000;
    position:absolute; 
    content: '';
    height: 7px;

    width: 7px; border-radius:50%;
    bottom: 12px;
}
.mainsecvsmain1 h1:before {
   left:-20px;
}
.mainsecvsmain1 h1:after {
   right:-20px;
}


.text11{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
 align-items: center;
 margin-bottom: 70px;
 font-size: 19px;
 font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
 text-align: center;
}
.text12{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

@media (max-width: 1100px) {


  .text11{
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: center;
   align-items: center;
   margin-bottom: 30px;
   font-size: 16px;
  }

  .sampleCodegiv1{
    display: none;
  }
  .mainsecvsmain1{
    display: flex;
  justify-content: center;
  font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding: 15px;
  }
  .mainsecvsmain1 h1 {
    display: flex;
    justify-content: center;
    position:relative; font-size:20px; font-weight:700; align-items: center; letter-spacing:0px; text-transform:uppercase; width:300px;  margin:auto; white-space:nowrap; border:2px solid #222;padding:5px 11px 3px 11px;
  }
  .givonebar{
    display:flex;
    justify-content: center;
    padding-bottom: 20px;
   
  }
  .givonebar img{
    display:flex;
    height: 250px;
    width: 400px;
  }
 
  .mainsecvs{
    padding-bottom: 0px;
   
  }
  .barformaingif{
    display: none;
  }

}

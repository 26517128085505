

.mainsecvs{
  display: flex;
  border-radius: 10px;

  justify-content: space-around;
  align-items: flex-start;
}
.mainsecvsmain3{
  display: flex;

  justify-content: flex-start;
  padding: 50px;
  font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.givonebar{
  display: none;
}
.mainsecvsmainp {
  background-color: #8efcbf;
  background-image: linear-gradient(62deg, #8efcbf 0%, #E0C3FC 50%);
  

 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width: 100%;
 padding-bottom: 50px;
}
.jusblurgifp{

  width: 100%;
  padding-bottom: 30px;
}

.samplechartgifrr{
  display: flex;
  justify-content: space-around;

}
.samplechartgifrr1{
 
  border: 1px solid black;
  border-radius: 5px;

}


.mainsecvsmain3 h1 {
  display: flex;
  justify-content: center;
  position:relative; font-size:20px; font-weight:700;  letter-spacing:0px; text-transform:uppercase; width:350px;  margin:auto; white-space:nowrap; border:2px solid #222;padding:5px 11px 3px 11px;
}
.mainsecvsmain3 h1:before, .mainsecvsmain3 h1:after {
    background-color: #c50000;
    position:absolute; 
    content: '';
    height: 7px;

    width: 7px; border-radius:50%;
    bottom: 12px;
}
.mainsecvsmain3 h1:before {
   left:-20px;
}
.mainsecvsmain3 h1:after {
   right:-20px;
}
@media (max-width: 1100px) {
  .mainsecvsmain3{
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .legend-funnel-item{
    font-size: 10px;
  }
  .funnel-names{
    font-size: 10px;
  }
  .givonebar{
    display:flex;
    justify-content: center;
    padding-bottom: 20px;
   
  }
  .givonebar img{
    display:flex;
    height: 250px;
    width: 390px;
  }
  
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;600&display=swap');


.home {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 520px;

 
}

.halfhe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  padding-bottom: 0;
  

  width: 100%;
 
 
  height: 520px;

 
font-family: "Poppins";

  background-color: #FFDEE9;
  background-image: linear-gradient(84deg, #FFDEE9 0%, #B5FFFC 100%);
  
}

.halfhe::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 50%;
  bottom: 0;
 
  background-size: contain;
  background-position: center;
  filter: blur(90px); /* Apply blur effect */
  z-index: 0;
}
.herotitlefo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




.content {
  position: relative;
  z-index: 1; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

}

.halfhe2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.TitleHero{
  display: flex;
  flex-direction: column;
align-items: center;
  font-size: 60px;
font-weight: 600;
font-family: "Poppins";

}
.spand2{

  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 60px;
  font-weight: 400;
  align-items: center;
}
.download {

  font-family: 'Courier New', Courier, monospace; /* Monospace font for terminal-like appearance */
  background-color: #333; /* Dark background for terminal look */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Adding some depth */
  width: 25%;
  font-size: 15px;
}

.d1{
  color: #045CE7;
  font-weight: bold;

}
.d2{
  color: #0BBB8B;
  font-weight: bold;
}



.icon {
  width: 18px; /* Adjust the size as needed */
  height: auto;
  vertical-align: middle; /* Align icon with text */
  margin-left: 15px; /* Space between icon and text */
  cursor: pointer; /* Change cursor to pointer on hover */
  filter: brightness(0) invert(1);
}

.tooltip {
  position: absolute;
  background-color: #232323;
  color: white;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;
  bottom: 13%;
  left: 98%;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
  width: 140px;
  font-size: 13px;
  font-family: "inter";
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: -20px; /* Adjust this to align the arrow with the icon */
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  z-index: 100;
  
}



.icon:hover + .tooltip {
  visibility: visible;
  opacity: 1;
  z-index: 100;

}

.icon-container {
  position: relative; /* Add this line */
  background-color: #232323; 
  color: #7fff00; /* Green color typical of terminal displays */
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.home, .gifvs, .gifvs1, .gifvs2, .action {
  animation: fadeIn 1s ease-in-out;
}


@media (max-width: 1100px) {

  .TitleHero{
    font-size: 40px;
    width: 100%;
  }
  .content{
    width: 100%;
  }
  .halfhe{
    padding: 20px;
    height: 530px;
  }
  .home{
    height: 570px;
  }
  .spand2{
    font-size: 19px;
    margin-top: 10px;
    align-items: center;
    font-family:'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .download{
    width: 97%;
    padding: 10px;
  
  }
  .icon-container{
    padding: 4px;
    font-size: 12px;
  }
  .mainsecvsmainpssa{
    display: none;
  }

 .herotitlefo{
  display: flex;
  justify-content: center;
align-items: center;
 }

  .text11001{
    font-size: 16px;
  }
  .text11002{
    display: none;
  }

}



@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;600&display=swap');

.mainchartsBar{
  display: flex;
  flex-direction: column;
  font-family:'Poppins','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 16px;
}

.mainchartsBar1{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  
}
.mainchartsBarooo{
  margin-bottom: 20px;
  align-items: center;
}
.mainchartsBaroooh{
  margin-bottom: 60px;
}
.mainchartsBaroooho{
  margin-bottom: 260px;
}
.mainchartsBarooocc{
  margin-top: 160px;
}
.dybar-chart{
  border: 1px solid black;
}
.sampleCodebar {
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  border: 1px solid black;
  border-radius: 5px;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
 
  background-color: #F5F2F0;
 

  /* Hide scrollbar for Chrome, Safari, and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.mainchartsBar2{
  display: flex;
}


.props-container {
  margin-right: 10px;
  margin-left: 10px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 15px;
  width: 50%;
  line-height: 1.2;
}

.props-container h2 {
  text-align: center;
  color: black;
  font-size: 28px;
  margin-bottom: 20px;
  font-family:'Roboto','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.props-container ul {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  display: flex; /* Ensure flex display */
  flex-direction: column; /* Align children in a column */
}

.props-container li {
  background-color: #f8f8f8;
  margin-bottom: 5px;
  padding: 10px;
  border-left: 5px solid #2a6496;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: all 0.3s ease;
  /* Ensure full width for each list item */
  width: 97%; 
  text-align: start;
  border: 1px solid black;
}

.props-container li:hover {
  background-color: #eef4ff;
  border-left: 5px solid #1a5276;
  transform: translateX(5px);
  cursor: pointer;
}

.props-container strong {
  color: #2a6496;
  font-weight: 600;
  display: inline-block;

}

@media (max-width: 768px) {
  .props-container {
      padding: 20px;
  }

  .props-container strong {
      min-width: 120px;
  }
}

.mainchartsBar3 {
  max-width: 800px; /* Adjust as needed */
  margin: 20px auto;
  padding: 20px;
  background-color: #f2f2f2; /* Light grey background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mainchartsBar3 .video-title {

  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.videofor-chartio {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  padding-top: 25px;
  height: 0;
}

.videofor-chartio iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.label-shifti{
  font-size: 13px !important;
}
.empty-square-shifti,.calendar-day-shifti,.legend-shifti-text,.day-name-shifti{
  font-size: 13px !important;
}
.calendar-day-shifti, .empty-square-shifti, .day-name-shifti {
  width: 3.33% !important;
  height: 23px !important;
}
.dy-table th, .dy-table td {
  padding: 13px 18px !important;
  text-align: left !important;
  border-bottom: 1px solid #E0E0E0 !important;
  font-size: 14px !important;
}
.content-detailporxs span{
  font-size: 14px !important;
}
.search-section button{
  font-size: 13px !important;
}
.export-button button{
  font-size: 13px !important;
}

.name-daydc{
  font-size: 13px !important;
}
.hour-daydc{
  font-size: 11px !important;
  width: 33px !important;
  height: 20px !important;
}
.legend-daygh-text{
  font-size: 13px !important;
}
.calendar-header-daydc h2{
  font-size: 19px !important;
}
.button-85 {
  padding: 7px 7px !important;
}
.number-itemopsyear {
font-size: 13px !important;
  width: 27px !important;
  height: 10px !important;
}
.calendar-bodyfoo{
 
  max-height: 30% !important;
 
}
.calendar-day{
  font-size: 13px !important;
}
.calendar-bodyfoo div {
  min-height: 20px !important;
}
.calendar-day{
  height: 20px !important;
}
/* .calendar-bodyfoo */
.prev-datesopsyear{
  height: 10px !important;
}
.calendaropyear{
  height: 500px !important;
}
.calendar-month-label{
  font-size: 16px !important;
}
.calendar-year-label{
  font-size: 25px !important;
}
.valuemain-titan{
  font-size: 13px !important;
}
.value-titan{
  font-size: 10px !important;
}
.calendar-day-titan span{
  font-size: 8px !important;
}
.custom-scroll{

justify-content: flex-start !important;
}
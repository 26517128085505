/* General Styles */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Roboto:wght@400&display=swap');

.dynamo-homepage{
  display: flex;
  align-items: center;
  justify-content: center;


  background-color: #FFDEE9;
  background-image: linear-gradient(84deg, #FFDEE9 0%, #B5FFFC 100%);
  
  padding: 40px;
}

.navbar-logodyoo{
  width: 60%;
 
  height: 10%;

}


.button1:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}


.button1 {
  background-color: #FF6384;
}
.button1 {
  border: 0;
  text-align: center;
  display: inline-block;
  padding: 10px;
  margin: 8px;
  color: #FFFFFF;
 width: 100px;
  border-radius: 6px;
font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
}

.button2:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}


.button2 {
  background-color: #36A2EB;
}
.button2 {
  border: 0;
  text-align: center;
  display: inline-block;
  padding: 10px;
  margin: 8px;
  color: #FFFFFF;
  width: 100px;
  border-radius: 6px;
font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
font-size: 14px;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
  
}


.button3:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}


.button3 {
  background-color: #4BC0C0;
}
.button3 {
  border: 0;
  text-align: center;
  display: inline-block;
  padding: 10px;
  margin: 8px;
  color: #FFFFFF;
  width: 100px;
  border-radius: 6px;
font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
}


.button4:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}


.button4 {
  background-color: #333538;
}
.button4 {
  border: 0;
  text-align: center;
  display: inline-block;
  padding: 10px;
  margin: 8px;
  color: #FFFFFF;
  width: 100px;
  border-radius: 6px;
font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
}
.button5:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}


.button5 {
  background-color: #238445;
}
.button5 {
  border: 0;
  text-align: center;
  display: inline-block;
  padding: 10px;
  margin: 8px;
  color: #FFFFFF;
  width: 100px;
  border-radius: 8px;
  font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  text-decoration: none;
  transition: box-shadow 200ms ease-out;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 3px;
  cursor: pointer;
  margin-left: 20px;
  width: 8%;
}
.navuloip {
  display: flex;
  list-style: none; /* This line removes the bullet points */
  padding: 0; /* This line removes the default padding */
  margin: 0; /* Optionally, you can also remove default margins */
}

/* ... rest of your CSS ... */

@media (max-width: 1100px) {
  .hamburger {
      display: block;
      position: absolute;
      right: 5%;
      top: 2%;
  }
  .button1,
  .button2,
  .button3,
  .button4,
  .button5{
width: 100px;
font-size: 14px;
padding: 3px;
margin: 4px;
  }
  nav {
      display: none;
      position: fixed;
      top: 60px; /* Adjust the top value as needed */
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
      z-index: 1000;
  }

  nav.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 35%;
    left: 64%;
    top: 6%;
    position: fixed; /* Ensure this is set */
    border-radius: 5px;
    border: 1px solid black;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: rgba(255, 255, 255, 0.9); /* Ensure background is set for visibility */
    z-index: 1000; /* Ensure z-index is high enough */
}

  ul {
     
      padding: 0;
  }

  li {
      width: 60%;
      text-align: center;
  }

  .navbar-logodyoo {
    width: 70%;
    height: auto;
    margin-top: 5px; /* Adjust height to maintain aspect ratio */
    z-index: 100;
  }
  .dynamo-homepage{
    padding: 10px;
  }
  .navuloip{
    flex-direction: column;
    background-color: transparent;

  }
}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;600&display=swap');

.cookie-consent {
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -0%);
  background-color: #c9c9c9;
  border: 2px solid #333;
  border-radius: 10px;
  padding: 20px;
  z-index: 9999;
  width: 97%;
 
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  text-align: center;
font-family:'Roboto','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #333;

}

.cookie-consent p {
  font-size: 18px;
  margin: 10px 0;
}

.cookie-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.cookie-buttons button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #007BFF;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.cookie-buttons button:hover {
  background-color: #0056b3;
}

/* Add any additional styling you need */

/* Hide the consent message */
.cookie-consent-hidden {
  display: none;
}

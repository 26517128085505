.sampleCode {
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  border: 1px solid black;
  border-radius: 5px;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 600px;
  background-color: #F5F2F0;
  width: 30%;

  /* Hide scrollbar for Chrome, Safari, and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}



.mainsecvs{
  display: flex;
  border-radius: 10px;

  justify-content: space-around;
  align-items: flex-start;
}
.mainsecvsmain2{
  display: flex;
  justify-content: center;

  padding: 50px;
  font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.mainsecvsmainrr {
  background-color: #A9C9FF;
  background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 50%, #fb7e7e 100%);
  

 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 padding-bottom: 50px;
}
.jusblurgif{

  width: 100%;
}


.samplechartgif1{
  width: 1100px;
  border: 1px solid black;
  border-radius: 5px;
  height: 600px;
}


.mainsecvsmain2 h1 {
  display: flex;
  justify-content: center;
  position:relative; font-size:20px; font-weight:700;  letter-spacing:0px; text-transform:uppercase; width:350px;  margin:auto; white-space:nowrap; border:2px solid #222;padding:5px 11px 3px 11px;
}
.mainsecvsmain2 h1:before, .mainsecvsmain2 h1:after {
    background-color: #c50000;
    position:absolute; 
    content: '';
    height: 7px;

    width: 7px; border-radius:50%;
    bottom: 12px;
}
.mainsecvsmain2 h1:before {
   left:-20px;
}
.mainsecvsmain2 h1:after {
   right:-20px;
}

@media (max-width: 1100px) {
 
  .mainsecvsmain2{
    display: flex;
  justify-content: center;
  font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding: 15px;
  }
  .mainsecvsmain2 h1 {
    display: flex;
    justify-content: center;
    position:relative; font-size:20px; font-weight:700; align-items: center; letter-spacing:0px; text-transform:uppercase; width:300px;  margin:auto; white-space:nowrap; border:2px solid #222;padding:5px 11px 3px 11px;
  }
}
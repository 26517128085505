

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;600&display=swap');

.samples-container {
  display: flex;
  font-family:'Poppins','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.samples-desktop{
  display: none;
}
.sidebar {
  flex: 0 0 15%;
  background: #1A202C;
  padding: 40px;
  overflow-y: auto; 
  
}

.page-content {
  overflow-y: auto; 
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  flex-grow: 3;
  padding-top: 40px;
  background-color: #FFDEE9;
  background-image: linear-gradient(84deg, #FFDEE9 0%, #B5FFFC 100%);
  font-family:"Inter," 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sidebar button {
  display: block;
  width: 100%;
  padding: 10px 10px;
  margin: 10px 0;
  background-color: transparent;
  border: none;
  color: #ecf0f1;
  text-align: left;
  font-size: 16px;
  border-left: 4px solid transparent;
  transition: all 0.3s ease-in-out;
}

.sidebar button:hover, .sidebar button:focus {
  background-color: #34495e;
  color: #fff;
  border-left-color: #3498db; 
  transform: translateX(10px);
  outline: none;
}

.sidebar button.active {
  background-color: #333; 
  color: #fff;
  border-left-color: #28a745; 
}


.samples-container  a, button {
  cursor: pointer;
  transition: color 0.2s;
}

::-webkit-scrollbar {
  width: 8px;
}


::-webkit-scrollbar-thumb {
  background-color: #bdc3c7;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #95a5a6;
}

@media screen and (max-width: 1024px) {

  .samples-container {
    display: none;
  }
  .samples-desktop{
   display: flex;
   font-family:'Poppins','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
   justify-content: center;
   padding-top: 100px;
   padding-bottom: 100px;
   background-color: #FFDEE9;
   background-image: linear-gradient(84deg, #FFDEE9 0%, #B5FFFC 100%);
}
}

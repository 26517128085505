@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;600&display=swap');

.get-started {
display:flex;
flex-direction: column;
background-color: #FFDEE9;
background-image: linear-gradient(84deg, #FFDEE9 0%, #B5FFFC 100%);
font-family:'Poppins','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.title1get span{
  color: #16129c;
  text-decoration: none;
  transition: color 0.3s;
}
.title1get{
  font-size: 30px;
  font-weight: bold;
  padding-top: 50px;
}
.codevideo{
  display: flex;
  justify-content: space-around;
align-items: center;

  height: 300px;
  
  
}
.linkforlearningdd {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  background-color: #36A2EB; 
  color: white; 
  text-align: center;
  text-decoration: none;
 font-size: 21px;
  border-radius: 5px; 
  transition: background-color 0.3s, box-shadow 0.3s; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
  cursor: pointer; 
}

.linkforlearningdd:hover,
.linkforlearningdd:focus {
  background-color: #0056b3; 
  text-decoration: none; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
}

.linkforlearningdd:active {
  background-color: #004085; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}


.linkforlearningdd:focus-visible {
  outline: 2px solid #004085;
  outline-offset: 2px;
}


@media screen and (max-width: 768px) {
  .linkforlearningdd {
      padding: 8px 16px;
      font-size: smaller; 
  }
  .step-image{
    display: none;
  }
  .stepotitleget{
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .firststepback{
    height: 300px;
  }
  .jusiframe1{
    display: none;
  }
  .code-snippet12 {
    height: 170px;
  }
  .step2{
    padding-top: 400px;
  }
  .title1get{
    padding-top: 0;
  }
  .codevideo2{
    height: 1100px;
  }
  .step3{
    margin-top: 400px;
  }
  .subsecound{
    font-size:20px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
   }
 
 
  
   .code-snippet2 th{
    background-color: #16129c;
   }

  

}

.codevideo2{
  display: flex;
  justify-content: space-around;
align-items: center;

  height: 900px;
}
.codevideo21{
  display: flex;
  justify-content: space-around;
align-items: center;
margin-top: 50px;
margin-bottom: 50px;


}
.jusiframe1{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.step {

  width: 100%;


}




.icon-container1 {
  display: flex;
  align-items: center;
  margin: 10px 0;
  position: relative; /* Add this line */
  background-color: #232323; /* Slightly lighter than download background for contrast */
  color: #7fff00; /* Green color typical of terminal displays */
  padding: 10px 20px;

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.icon1 {
  width: 18px; /* Adjust the size as needed */
  height: auto;
  vertical-align: middle; /* Align icon with text */
  margin-left: 15px; /* Space between icon and text */
  cursor: pointer; /* Change cursor to pointer on hover */
  filter: brightness(0) invert(1);
}

.tooltip {
  position: absolute;
  background-color: #232323;
  color: white;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;
  bottom: 13%;
  left: 98%;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
  width: 140px;
  font-size: 15px;
  font-family: "inter";
}
.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: -20px; /* Adjust this to align the arrow with the icon */
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  z-index: 100;
  
}



.icon:hover + .tooltip {
  visibility: visible;
  opacity: 1;
  z-index: 100;

}
.icon-container:last-child {
  margin-bottom: 0;
}

.code-snippet12 {
  display: flex;
  flex-direction: column;
  height: 130px;
  font-family: 'Courier New', Courier, monospace; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); 
background-color: #333333 ;
margin: 5px;
}

.step-image {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}
.stepotitleget{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}


.subsecound{
  display: flex;
  justify-content: center;
  color: black;
  align-items: center;
  text-align: center;
  font-size:25px;
  padding-left: 200px;
  padding-right: 200px;
  margin-top: 40px;
  margin-bottom: 70px;
}





.code-snippet2 {
  font-family:'inter','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  overflow-x: auto;
  margin: 20px 0; 
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
  border-radius: 8px; 
  overflow: hidden; 
}

.code-snippet2 table {
  width: 100%; /* Full width */
  border-collapse: separate; /* Ensures borders are separated */
  border-spacing: 0; /* Removes default spacing */
}

.code-snippet2 th, .code-snippet2 td {
  padding: 12px 20px; /* More space within each cell */
  text-align: left; /* Aligns content to the left */
  border-bottom: 1px solid #ddd; /* Adds a border to each cell */
}

.code-snippet2 th {
  background-color: #4CAF50; /* A deep green background for headers */
  color: white; /* White text for contrast */
  font-size: 14px; /* Slightly larger font for headers */
}

.code-snippet2 tbody tr:nth-child(odd) {
  background-color: #f3f3f3; /* Light gray for odd rows */
}

.code-snippet2 tbody tr:nth-child(even) {
  background-color: #ffffff; /* White for even rows */
}

.code-snippet2 tr:hover {
  background-color: #ddd; /* A hover effect for each row */
  cursor: pointer; /* Changes the cursor to indicate interactivity */
}

.code-snippet2 td {
  vertical-align: middle; /* Centers content vertically in cells */
}

.mainsecvsmainpssa {

  height: 800px;
  width: 100%;

  
  background-image: url('../res/allchart.png'), linear-gradient(84deg, #FFDEE9 10%, #B5FFFC 90%);
  
 
  background-size: contain, auto; 
  background-position: center center; 
  background-repeat: no-repeat; 
  background-color: #FFDEE9;
  padding-bottom: 20px;
  padding: 5px;
}
